import React from "react";
import { graphql } from "gatsby";
import CareerLinkBox from "../../components/career-link-box";
import PathwaysIntro from "../../components/pathways-intro";
import HeroPage from "../../components/hero-page";
import Pathways from "../../components/pathways";
import Seo from "../../components/seo";

const BioMedical = ({ data }) => {
  return (
    <>
      <Seo
        title="Bio Medical Industry, North Central Pennsylvania"
        description="The Bio-Medical (BM) cluster includes industries that develop and use technology to enhance life from a health perspective"
        featuredImage={data.opengraphImage.sharp.image}
      />

      <HeroPage
        image={data.heroImage.sharp.fluid}
        title="work"
        caption=""
        position="30%"
      />
      <main>
        <section className="page-intro">
          <div className="intro-title">
            <h2>Bio Medical</h2>
            <p>
              The Bio-Medical (BM) cluster includes industries that develop and
              use technology to enhance life from a health perspective.
            </p>
          </div>

          <div className="intro-text">
            <p>
              Industries in this cluster include research laboratories,
              pharmaceutical manufacturing, surgical and medical equipment
              manufacturing, and imaging centers.
            </p>

            <p>
              This cluster largely represents industries that employ advanced
              technology and serve as a foundation for enhanced medical
              services.
            </p>
          </div>
        </section>

        <CareerLinkBox image={data.careerImage.sharp.image} caption="" />
      </main>

      <PathwaysIntro
        industry="Bio Medical"
        image={data.pathwaysImage.sharp.image}
        caption=""
      />

      <Pathways pathway={data.pathways.pathway} />
    </>
  );
};

export const data = graphql`
  query {
    pathways: pathwaysYaml {
      pathway: biomedical {
        name
        associate {
          name
          occupations
          salary
          skills
        }
        diploma {
          name
          occupations
          salary
          skills
        }
        certificate {
          name
          occupations
          salary
          skills
        }
        bachelor {
          name
          occupations
          salary
          skills
        }
      }
    }

    careerImage: file(
      relativePath: { eq: "thisisengineering-raeng-AgVIv5RQGQI-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    pathwaysImage: file(
      relativePath: { eq: "thisisengineering-raeng-CnDBjaddRWs-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        image: gatsbyImageData
      }
    }

    heroImage: file(
      relativePath: { eq: "thisisengineering-raeng-n1GyZk-MjrM-unsplash.jpg" }
    ) {
      sharp: childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    opengraphImage: file(relativePath: { eq: "thisisengineering-raeng-n1GyZk-MjrM-unsplash.jpg" }) {
      sharp: childImageSharp {
        image: gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`;

export default BioMedical;
